<template>
  <b-card>
    <b-form>
      <b-row>
        <b-col>
          <br>
          <b-form-group
            label-cols-md="4"
            label="Kendaraan"
          >
            <v-select
              v-model="fieldForm.vehicle"
              label="number"
              placeholder="Pilih Kendaraan"
              :options="optionVehicle"
              @input="changeSelectVehicle"
            />
          </b-form-group>

          <b-form-group
            label-cols-md="4"
            label="Jenis BBM"
          >
            <v-select
              v-model="fieldForm.fuel"
              label="name"
              placeholder="Pilih Jenis BBM"
              :options="optionFuel"
              @input="changeSelectfuel"
            />
          </b-form-group>
          <b-form-group
            label-cols-md="4"
            label="Tanggal"
            content-cols-md="3"
          >
            <flat-pickr
              v-model="fieldForm.date"
              class="form-control"
              placeholder="Tanggal Pengisiian"
            />
          </b-form-group>
          <div class="row mb-1">
            <div class="col-md-4 pt-1">
              <label for="">Harga</label>
            </div>
            <div class="col-md-3" style="padding: 7px">
              <cleave
                  size="3"
                  v-model="fieldForm.price"
                  class="form-control"
                  :raw="true"
                  :options="options.number"
                  placeholder="Harga"
                  required=""
                />
            </div>
          </div>
          <form-input
            v-model="fieldForm.liter"
            size="3"
            label="Jumlah (Liter)"
            :readonly="'readonly'"
            :disabled="liter_disabled"
            placeholder="Jumlah (Liter)"
          />
          <form-input
            v-model="fieldForm.odometer"
            size="3"
            label="KM"
            type="number"
            placeholder="KM"
          />
          <form-input
            v-model="fieldForm.note"
            label="Keterangan"
            placeholder="Diisi Apabila Tidak Membeli di Pom Bensin Resmi"
            @input="changeNote"
          />
          <form-file v-model="img_odometer" label="Foto Odometer" />
          <form-file v-model="img_receipt" label="Foto Struk Pembelian" />
          <b-form-group
            label-cols-md="4"
            label=""
          >
            <b-button
              class="mr-1"
              variant="primary"
              type="button"
              @click.prevent="validationForm"
            >
              <span v-if="loading">
                <b-spinner small />
                Loading...
              </span>
              <span v-else>Simpan</span>
            </b-button>
            <b-button
              class="mr-1"
              variant="warning"
              type="button"
              @click.prevent="batal"
            >
              <span>Batal</span>
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {BCard, BCol, BRow, BButton, BBadge, BForm, BFormGroup, BFormCheckbox, BSpinner} from 'bootstrap-vue'
import FormInput from '@/views/base/form/FormInput.vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FormFile from '@/views/base/form/FormFile.vue'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    BCard,
    BCol, 
    BRow, 
    BForm, 
    BButton,
    BFormGroup,
    FormInput,
    vSelect,
    BFormCheckbox,
    BSpinner,
    flatPickr,
    FormFile,
    Cleave
  },
   data() {
    return {   
      loading :false,
      optionVehicle :[],
      optionFuel :[],
      idForm:null,
      fieldForm:{
        vehicle:null,
        fuel:null,
        vehicle_id:null,
        number:null,
        date: new Date,
        price:null,
        liter:'0',
        km:null,
        fuel_id:null,
        location: "-",
        longitude:0,
        latitude:0,
        note:null
      },
      img_odometer: null,
      img_receipt:null,
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
      liter_disabled: true,
    }
  },
  watch:{
    'fieldForm.price': function() {
      if(!this.fieldForm.fuel)
        return this.$bvToast.toast('Jenis BBM tidak diketahui', {
          title: 'Error',
          solid: true,
          variant:'danger'
        })

      this.fieldForm.liter = String(parseFloat((this.fieldForm.price / this.fieldForm.fuel.price)).toFixed(2))
    }
  },
  mounted(){
    this.getVehicle()
    this.getfuel()
    if(this.$route.params.id != null){
        this.$http.get('/refueling/'+this.$route.params.id).then(res=>{
          this.idForm = res.data.id
          this.fieldForm={
                vehicle:res.data.vehicle,
                fuel:res.data.fuel,
                vehicle_id:res.data.vehicle.id,
                number:res.data.number,
                date:res.data.date,
                liter:res.data.liter,
                odometer:res.data.odometer,
                fuel_id:res.data.fuel.id,
                price:res.data.price,
                note:res.data.note,
              }
        })
    }
  },
  methods:{
    getVehicle(){
      this.$http.get('vehicle?length=').then(res=>{
        this.optionVehicle = res.data.data
      })
    },
    getfuel(){
      this.$http.get('fuel').then(res=>{
        this.optionFuel = res.data.data
      })
    },
    async validationForm() {
      this.loading = true
      if(this.img_odometer){
        let formData = new FormData()
        formData.append('file', this.img_odometer)
        let res_odo = await this.$http.post('upload/image', formData, 
                      {headers: { 'Content-Type': 'multipart/form-data' }})
                      .catch(err=>{ return this.errorSubmit(err)})
        
        if (res_odo.data)
          this.fieldForm.odometer_evidence = res_odo.data.path
      }

      if(this.img_receipt){
        let formData = new FormData()
        formData.append('file', this.img_receipt)
        let res_rec = await this.$http.post('upload/image', formData, 
                      {headers: { 'Content-Type': 'multipart/form-data' }})
                      .catch(err=>{ return this.errorSubmit(err)})
        
        if (res_rec.data)
          this.fieldForm.receipt_evidence = res_rec.data.path
      }

      if(this.$route.params.id == null)
        this.$http.post('/refueling', this.fieldForm)
        .then(res=>{
          this.idForm = res.data.data.id
          this.successSubmit()
        })
        .catch(err=>{
          this.errorSubmit(err)
        })
        .finally(() => {this.loading = false})
      else
        this.$http.put('/refueling/'+this.$route.params.id, this.fieldForm)
        .then(()=>{
          this.successSubmit()
        })
        .catch(err=>{
          this.errorSubmit(err)
        })
        .finally(() => {this.loading = false})
    },
    changeSelectVehicle(data){
      if(data != null){
        this.fieldForm.vehicle_id = data.id
        this.fieldForm.vehicle.id = data.id
        this.fieldForm.vehicle.name = data.name
        this.fieldForm.odometer = data.last_km ? data.last_km : 0
      }
    },
    changeSelectfuel(data){
      if(data != null){
        this.fieldForm.fuel_id = data.id
        this.fieldForm.fuel.id = data.id
        this.fieldForm.fuel.name = data.name
      }
    },
    successSubmit(){
      this.loading = false
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Submit Success',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
      if(this.ismodal){
      this.$emit("onsubmite", true)
      }else{
        this.$router.push({ name:'refueling' })
      }
    },
    errorSubmit(err){
      const msg = err.response.data.message
      this.$bvToast.toast((msg)?msg:'Submit error', {
        title: 'Error',
        solid: true,
        variant:'danger'
      })
    },
    batal(){
      this.$router.go(-1)
    },
    changeNote(data){
      if (data.length > 0){
        this.liter_disabled = false
      }
      else{
        this.liter_disabled = true
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>